var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isBolusTimepoint)?_c('span',{staticClass:"d-inline-block icon-container"},[(_vm.timePoint === 'bolus_breakfast')?_c('span',{class:{
      'deleted-dose-color': _vm.useDeletedColor,
      'icon-blue': !_vm.useDeletedColor,
    }},[_c('IconCoffee',{class:{ small: _vm.small }})],1):(_vm.timePoint === 'bolus_lunch')?_c('span',{class:{
      'deleted-dose-color': _vm.useDeletedColor,
      'icon-pink': !_vm.useDeletedColor,
    }},[_c('IconLunch',{class:{ small: _vm.small }})],1):(_vm.timePoint === 'bolus_dinner')?_c('span',{class:{
      'deleted-dose-color': _vm.useDeletedColor,
      'icon-green': !_vm.useDeletedColor,
    }},[_c('IconCooking',{class:{ small: _vm.small }})],1):(_vm.timePoint === 'bolus_extra_prescription')?_c('span',{class:{
      'deleted-dose-color': _vm.useDeletedColor,
      'icon-true-blue': !_vm.useDeletedColor,
    }},[_c('IconFourthMeal',{class:{ small: _vm.small }})],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }