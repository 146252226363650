<template>
  <span v-if="isBolusTimepoint" class="d-inline-block icon-container">
    <span
      v-if="timePoint === 'bolus_breakfast'"
      :class="{
        'deleted-dose-color': useDeletedColor,
        'icon-blue': !useDeletedColor,
      }"
    >
      <IconCoffee :class="{ small: small }" />
    </span>
    <span
      v-else-if="timePoint === 'bolus_lunch'"
      :class="{
        'deleted-dose-color': useDeletedColor,
        'icon-pink': !useDeletedColor,
      }"
    >
      <IconLunch :class="{ small: small }" />
    </span>
    <span
      v-else-if="timePoint === 'bolus_dinner'"
      :class="{
        'deleted-dose-color': useDeletedColor,
        'icon-green': !useDeletedColor,
      }"
    >
      <IconCooking :class="{ small: small }" />
    </span>
    <span
      v-else-if="timePoint === 'bolus_extra_prescription'"
      :class="{
        'deleted-dose-color': useDeletedColor,
        'icon-true-blue': !useDeletedColor,
      }"
    >
      <IconFourthMeal :class="{ small: small }" />
    </span>
  </span>
</template>

<script>
export default {
  name: 'BolusTimepointIcon',
  components: {
    IconFourthMeal: () => import('./icons/IconFourthMeal'),
    IconCooking: () => import('./icons/IconCooking'),
    IconLunch: () => import('./icons/IconLunch'),
    IconCoffee: () => import('./icons/IconCoffee'),
  },
  props: {
    timePoint: { type: String, required: true },
    small: { type: Boolean, required: false, default: false },
    useDeletedColor: { type: Boolean, required: false, default: false },
  },
  computed: {
    isBolusTimepoint() {
      return this.timePoint.startsWith('bolus_')
    },
  },
}
</script>

<style scoped lang="scss">
.icon-container {
  width: 22px;
  margin-right: 8px;
}
.icon-blue {
  color: $nn-sea-blue;
}

.icon-sand {
  color: $nn-D_T20;
}

.icon-true-blue {
  color: $nn-true-blue;
}

.icon-green {
  color: $nn-ocean-green;
}

.icon-pink {
  color: $nn-rose-pink;
}

.small {
  transform: scale(0.8);
}
</style>
