var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.doses),function(dose,index){return _c('div',{key:index,staticClass:"mb-4"},[(dose.isActive)?_c('v-row',[_c('v-col',{class:[
          { 'py-0': _vm.isBolusTimePoint(dose.timepoint) },
          { 'py-2': !_vm.isBolusTimePoint(dose.timepoint) },
        ],attrs:{"sm":"5"}},[_c('h6',{staticClass:"d-flex align-center"},[_c('BolusTimepointIcon',{attrs:{"time-point":dose.timepoint}}),_vm._v(" "+_vm._s(_vm.$t(`time-point.${dose.timepoint}`))+" ")],1)]),_c('v-col',{class:[
          { 'py-0': _vm.isBolusTimePoint(dose.timepoint) },
          { 'py-2': !_vm.isBolusTimePoint(dose.timepoint) },
        ],attrs:{"sm":"7"}},[_c('h5',[_vm._v(_vm._s(dose.doseValue)+" "+_vm._s(dose.dosingDisplayUnit))])])],1):_vm._e(),(dose.timepoint === 'basal_once_weekly')?[_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"sm":"5"}},[_vm._v(" Dosing day ")]),_c('v-col',{staticClass:"py-1 text-capitalize",attrs:{"sm":"7"}},[_vm._v(" "+_vm._s(dose.dosingDay)+" ")])],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"sm":"5"}},[_vm._v(" Start day ")]),_c('v-col',{staticClass:"py-1",attrs:{"sm":"7"}},[_c('DateFormat',{attrs:{"value":dose.effectiveDosingStartDate.time}})],1)],1)]:_vm._e()],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }