<template>
  <div>
    <div v-for="(dose, index) in doses" :key="index" class="mb-4">
      <v-row v-if="dose.isActive">
        <v-col
          sm="5"
          :class="[
            { 'py-0': isBolusTimePoint(dose.timepoint) },
            { 'py-2': !isBolusTimePoint(dose.timepoint) },
          ]"
        >
          <h6 class="d-flex align-center">
            <BolusTimepointIcon :time-point="dose.timepoint" />
            {{ $t(`time-point.${dose.timepoint}`) }}
          </h6>
        </v-col>
        <v-col
          sm="7"
          :class="[
            { 'py-0': isBolusTimePoint(dose.timepoint) },
            { 'py-2': !isBolusTimePoint(dose.timepoint) },
          ]"
        >
          <h5>{{ dose.doseValue }} {{ dose.dosingDisplayUnit }}</h5>
        </v-col>
      </v-row>

      <template v-if="dose.timepoint === 'basal_once_weekly'">
        <v-row>
          <v-col sm="5" class="py-1"> Dosing day </v-col>
          <v-col sm="7" class="py-1 text-capitalize">
            {{ dose.dosingDay }}
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="5" class="py-1"> Start day </v-col>
          <v-col sm="7" class="py-1">
            <DateFormat :value="dose.effectiveDosingStartDate.time" />
          </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>

<script>
import DateFormat from '../../DateFormat'
import BolusTimepointIcon from '../../BolusTimepointIcon'
export default {
  name: 'ConfirmDoseList',
  components: { BolusTimepointIcon, DateFormat },
  props: {
    doses: { type: Array, required: true },
  },
  methods: {
    isBolusTimePoint(timepoint) {
      return timepoint.startsWith('bolus_')
    },
  },
}
</script>

<style scoped></style>
